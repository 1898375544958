<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__header">
        <h3>Addresses</h3>
      </div>
      <div class="ps-section__content">
        <div class="form-group submit">
          <router-link :to="{name: 'add_address'}" class="ps-btn btn-sm">Add Billing Address</router-link>
          <router-link :to="{name: 'add_address'}" class="ps-btn btn-sm">Add Shipping Address</router-link>
        </div>
        <div class="table-responsive">
          <table class="table ps-table ps-table--invoices table-bordered">
            <thead class="thead-light">
            <tr>
              <th>Street</th>
              <th>City</th>
              <th>Province</th>
              <th>Country</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="address in addresses" :key="address.id">
              <td><a :href="`/user-order/${address.id}`">Ref-{{address.street}}</a></td>
              <td>{{ address.city }}</td>
              <td>{{ address.province }}</td>
              <td>{{ address.country }}</td>
            </tr>
            </tbody>
            <tfoot v-if="!addresses || addresses.length === 0 ">
            <tr>
              <td colspan="4" class="text-center m-4 text-danger">No addresses available</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import UserSideNav from '@/components/mechantnavs/UserSideNav.vue';
import global from '@/mixins/global';
import axios from "axios";

export default {
  mixins:[global],
  data() {
    return {
      loading: true,
      addresses: [],
    };
  },
  mounted() {
    this.getAddresses();
  },

  methods: {
    getAddresses() {
      axios.get("address").then((response) => {
          this.addresses = response.data.data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
    },
  }
}
</script>

<style>
</style>